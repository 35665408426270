import React from "react"
import { graphql } from "gatsby"
import Post from "../components/post"

const unified = require('unified')
const markdown = require('remark-parse')
const remark2rehype = require('remark-rehype')
const html = require('rehype-stringify')
const format = require('rehype-format')


const convert = (input: string) => {
  const processor = unified()
    .use(markdown)
    .use(remark2rehype)
    .use(format)
    .use(html)
  return processor.processSync(input).toString()
}

const nextCompoenent = (next) => {
  if (next) {
    return (
      <div>tugi
        {next.id}
        {next.title}
        {next.permalink}
      </div>
    )
  }
  return (<div>一番古い記事です</div>)
}

const previousCompoenent = (previous) => {
  if (previous) {
    return (
      <div>mae
        {previous.id}
        {previous.title}
        {previous.permalink}
      </div>
    )
  }
  return (<div>最新の記事です</div>)
}


export default ({ pageContext, data }) => {
  const page = data.posts
  const { next, previous } = pageContext

  const nex = nextCompoenent(next)
  const pre = previousCompoenent(previous)

  let convHTML
  convHTML = convert(page.body)
  return (
    <Post>
      {page.title}<br />
      {page.permalink}<br />
      <div dangerouslySetInnerHTML={{ __html: convHTML }}></div>

      {pre} - {nex}


    </Post>
  )
}

export const pageQuery = graphql`
query PostQuery($id: String!) {
    site {
        siteMetadata {
          title
        }
    }
    posts(id: {eq: $id}) {
        id
        title
        body
        tags
        permalink
    }
}
`